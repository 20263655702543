angular.module('fingerink')
    .service('selectContactModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance =  $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/selectContactModal.tpl.html',
                controller: 'selectContactModalCtrl',
                controllerAs: 'controller',
                animation: false,
                size: 'lg'
            });
            
            return this.modalInstance;
        };

    })
    .controller('selectContactModalCtrl', function selectContactModalCtrl($scope, session, userService, $timeout, $uibModalInstance, $translate, $uibModal, contactService, $q) {

        var that = this;

        //that.contactsMap = [];


        var promises = [contactService.getContactoss(), contactService.getContactosGrupos()];
        $q.all(promises).then(function (responses) {
            that.contacts = responses[0].data;
            that.groups = responses[1].data;
            that.groups.unshift({
                groupName: $translate.instant('Todos'),
                groupUsers: that.contacts
            });
            that.groupSelected = that.groups[0];
            that.loaded = true;
        });

        that.close = function () {
            $uibModalInstance.close();
        };

        that.guardar = function(){
            $uibModalInstance.close(that.contactsSelected);
        };
        that.contactsSelected = [];

        that.isSelected = function (id) {
            for (var j = 0; j < that.contactsSelected.length; j++) {
                if (that.contactsSelected[j].id == id) {
                    return true;
                }
            }
            return false;
        };

        that.isInGroup = function (id) {
            for (var j = 0; j < that.groupSelected.groupUsers.length; j++) {
                if (that.groupSelected.groupUsers[j].id == id) {
                    return true;
                }
            }
            return false;
        };
        that.addAll = function(){
            for (var j = 0; j < that.groupSelected.groupUsers.length; j++) {
                if(!that.groupSelected.groupUsers[j].checked){
                    that.groupSelected.groupUsers[j].checked = true;
                    that.change(that.groupSelected.groupUsers[j]);
                }
            }
        };
    
        that.change = function (contact) {
            if (!contact.checked) {
                for (var j = 0; j < that.contactsSelected.length; j++) {
                    if (that.contactsSelected[j].id == contact.id) {
                        that.contactsSelected.splice(j, 1);
                        contact.checked = false;
                        return;
                    }
                    contact.checked = false;
                }
            } else {
                that.contactsSelected.push(contact);
                contact.checked = true;
            }
        };



    });

